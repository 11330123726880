
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BTable,
  BPagination,
  BFormInput,
} from "bootstrap-vue";
import { AvButton, AvCheckbox, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";
import { IOption } from "@/@core/services/interfaces/IUtil";
import vSelect from "vue-select";
import ModalGenerateSendCollectionLetter from "./components/ModalGenerateSendChargeLetter.vue";

// Services
import { useOldService } from "@/services";

// Interfaces
import { IMonth } from "@/store/admin-panel/effectivate-billing/effectivateBillingState";
import {
  IBusinessBilling,
  IParameters,
  IResponseGetBusinessWithBilling,
} from "@core/services/interfaces/oldService/IOldService";

interface IItem extends IBusinessBilling {
  checked: boolean;
}

@Component({
  name: "EffectivateBillingTabReport",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BTable,
    BPagination,
    BFormInput,
    AvButton,
    AvExportButton,
    AvCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    AvSkeletonTable,
    ModalGenerateSendCollectionLetter,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
})
export default class EffectivateBillingTabReport extends Vue {
  @Prop({ required: true }) parameters!: IParameters;

  // Data
  $ENUMS = ENUMS;
  saving = false;
  formValidation = formValidation();
  yearFilter = new Date().getFullYear();
  yearsOptions: IOption[] = [];
  monthFilter = new Date().getMonth();
  items: IItem[] = [];
  totalRows = 0;
  currentPage = 1;
  perPageOptions = [10, 25, 50, 100];
  perPage = 10;
  selecteds = [];
  filter: string = "";
  loading = false;
  modalSendShow: boolean = false;
  tableColumns = [
    { key: "checked", label: "Faturar", tdClass: "text-center" },
    { key: "RazaoSocial", label: "Razão Social" },
    { key: "Cnpj", label: "CNPJ" },
    { key: "Codigo", label: "Código" },
    { key: "TotalFaturamentos", label: "Total Faturamentos" },
  ];

  // Computeds
  get monthsOptions(): IOption[] {
    return this.$store.getters["effectivate-billing/monthsOptions"].map(
      (month: IMonth) => ({
        label: month.Value,
        value: month.Key,
      })
    );
  }

  get dataMeta(): {
    from: number;
    to: number;
    of: number;
    total: number;
  } {
    const localItemsCount = this.items.length;
    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: this.perPage * (this.currentPage - 1) + this.perPage,
      of: localItemsCount,
      total: this.items.length,
    };
  }

  get monthLabel(): { label: string; value: number } {
    const index = this.monthsOptions.findIndex(
      (month) => month.value == this.monthFilter
    );

    return index >= 0 ? this.monthsOptions[index] : { label: "", value: 0 };
  }

  get rowsSelecteds(): IItem[] {
    return this.items.filter((item) => item.checked);
  }

  // LifeCycle
  created() {
    this.fetchYearsOptions();
  }

  // Watchs
  @Watch("yearFilter")
  resetMonth() {
    this.monthFilter = 1;
  }

  // Methods
  fetchGetBusinessWithBilling() {
    this.loading = true;
    useOldService
      .requestGetBusinessWithBilling(this.yearFilter, this.monthFilter)
      .then((response: IResponseGetBusinessWithBilling) => {
        this.items = response.data.data.map((billing) => ({
          ...billing,
          checked: true,
        }));
        this.totalRows = response.data.data.length;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar empresas com faturamento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return {
          data: [],
          total: 0,
          display: 0,
        };
      })
      .finally(() => {
        this.loading = false;
      });
  }

  fetchYearsOptions() {
    const initialYear = 2008;
    const currentYear = new Date().getFullYear();

    for (let year = currentYear; year > initialYear; year--) {
      this.yearsOptions.push({ label: "" + year, value: year });
    }
  }

  reduceValue(val: { label: string; value: any }) {
    return val.value;
  }

  generateFile() {
    return new Promise((resolve, reject) => {
      try {
        const business = this.items.filter((item) => item.checked);
        const parameters: IParameters = this.parameters;

        const exportData = business.map((item, index) => {
          let billingBalance = 0;
          try {
            billingBalance =
              item.TotalFaturamentos *
              parseFloat(parameters.ContribuicaoAssociativa.replace(",", ""));
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Atenção",
                text: "A contribuição associativa não está definida para a realização do cálculo",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });
          }

          return {
            "N Documento": parameters.NumDocumento,
            "Cliente Fornecedor": item.RazaoSocial,
            "CPF CNPJ": item.Cnpj,
            "Data de Entrada Saida": parameters.DataEntradaSaida,
            "Data de Emissao": parameters.DataEmissao,
            "Cabecalho doc": index + 1,
            historico: parameters.Historico,
            "Produto ServicoP ou S": parameters.ProdutoServico,
            Item: parameters.Item,
            Codigo: parameters.Codigo,
            Vendedor: parameters.Vendedor,
            Natureza: parameters.Natureza,
            "UM Comercial": parameters.UmComercial,
            "Quantidade Comercial": parameters.QtdComercial.replace(".", ""),
            "Preco Unidade Comercial": billingBalance
              .toString()
              .replace(".", ","),
            Quantidade: item.TotalFaturamentos,
            "Preco Unitario": billingBalance.toString().replace(".", ","),
            frete: "",
            acrescimo: "",
            Variante: "",
            "Valor do Item": billingBalance.toString().replace(".", ","),
            desconto: "",
          };
        });

        resolve(exportData);
      } catch (error) {
        reject(error);
      }
    });
  }
}
