
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
  BListGroup,
  BProgress,
  BProgressBar,
  BListGroupItem,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";
import { convertToUSDate } from "@/services/utils";

// Services
import { useOldService } from "@/services";

// Interfaces
import { IParameters } from "@core/services/interfaces/oldService/IOldService";

@Component({
  name: "EffectivateBillingTabParameters",
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BListGroup,
    BProgress,
    BProgressBar,
    BListGroupItem,
    BInputGroupAppend,
    AvButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
})
export default class EffectivateBillingTabParameters extends Vue {
  @Prop({ required: true }) parameters!: IParameters;

  // Data
  $ENUMS = ENUMS;
  saving = false;
  formValidation = formValidation();
  parametersLocal = {} as IParameters;
  flatPickrConfig = {
    dateFormat: "d/m/Y",
    locale: this.$t("flatPickr.locale"),
  };

  // LifeCycle
  created() {
    this.refreshData();
  }

  @Watch("parameters")
  refreshData() {
    this.parametersLocal = this.parameters;
  }

  // Methods
  onSubmit() {
    this.saving = true;
    const p = {
      ...this.parametersLocal,
      DataEntradaSaida:
        this.parametersLocal.DataEntradaSaida != "" &&
        this.parametersLocal.DataEntradaSaida != null
          ? convertToUSDate(this.parametersLocal.DataEntradaSaida)
          : "",
      DataEmissao:
        this.parametersLocal.DataEmissao != "" &&
        this.parametersLocal.DataEmissao != null
          ? convertToUSDate(this.parametersLocal.DataEmissao)
          : "",
      DataVencimento:
        this.parametersLocal.DataVencimento != "" &&
        this.parametersLocal.DataVencimento != null
          ? convertToUSDate(this.parametersLocal.DataVencimento)
          : "",
    };

    useOldService
      .requestUpdateBillingParameters(p)
      .then(() => {
        this.$emit("refreshParameters", p);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Parâmetros de faturamento atualizado com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao tentar atualizar parâmetros de faturamento",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.saving = false;
      });
  }
}
